// Cookies客户端操作模块
//
//  依赖
//      （无）
//
//  历史
//      创建    LEE     2021/03/04
//=============================================================================
//=============================================================================
// object ClientCookies (class)
//
function ClientCookies(vDocument)
{
    //=====================================================
    // Attributes
    //
    // document
    this.m_Document = vDocument;
    // cookie value name
    this.m_ValueName = new Array();
    // cookie value content
    this.m_ValueContent = new Array();
    // cookie value deleted
    this.m_ValueDeleted = new Array();
    // cookie expire
    this.m_Expires = "";
    // cookie domain
    this.m_Domain = "";
    // cookie path
    this.m_Path = "";
    // cookie secure
    this.m_Secure = false;
    
    
    //=====================================================
    // Functions
    //
    //-----------------------------------------------------
    // save cookies
    //
    this.SaveCookies = function()
    {
        var vIndex = 0;
        var vValue = "";        
        // check length
        if(this.m_ValueName.length == 0)
        {
            return;
        }
    
        // each value add
        for(vIndex = 0; vIndex < this.m_ValueName.length; vIndex++)
        {
            // check name empty
            if(this.m_ValueName[vIndex] == "")
            {
                continue;
            }
        
            // clear value
            vValue = "";
            
            // add content
            vValue += this.m_ValueName[vIndex];
            vValue += "=";
            vValue += escape(this.m_ValueContent[vIndex]);
            vValue += "; ";
            
            // check deleted
            if(this.m_ValueDeleted[vIndex])
            {            
                // document
                this.m_Document.cookie = vValue;
                
                // next
                continue;
            }
            
            // add expires
            if(this.m_Expires != "")
            {
                // add expires
                vValue += "expires";
                vValue += "=";
                vValue += this.m_Expires;
                vValue += "; ";
            }
            
            // add domain
            if(this.m_Domain != "")
            {
                // add domain
                vValue += "domain";
                vValue += "=";
                vValue += this.m_Domain;
                vValue += "; ";
            }
            
            // add path
            if(this.m_Path != "")
            {
                // add path
                vValue += "path";
                vValue += "=";
                vValue += this.m_Path;
                vValue += "; ";
            }
            
            // add secure
            if(this.m_Secure)
            {
                // add secure
                vValue += "secure; ";
            }
            console.log(vValue);
            // set cookie
            this.m_Document.cookie = vValue;
        }
    };
    
    //-----------------------------------------------------
    // get cookies
    //
    this.GetCookies = function()
    {
        var vIndex = 0;
        var vArray = this.m_Document.cookie.split("; ");
        var vContent = null;
        
        // clear value
        delete this.m_ValueName;
        delete this.m_ValueContent;
        delete this.m_ValueDeleted;
        this.m_ValueName = new Array();
        this.m_ValueContent = new Array();
        this.m_ValueDeleted = new Array();
        
        // each object
        for(vIndex = 0; vIndex < vArray.length; vIndex++)
        {            
            // check not empty
            if(vArray[vIndex] != "")
            {
                // get item
                vContent = vArray[vIndex].split("=");
                                
                // check length
                if(vContent.length != 2)
                {
                    continue;
                }
                
                // add value
                this.m_ValueName.push(vContent[0]);
                this.m_ValueContent.push(unescape(vContent[1]));
                this.m_ValueDeleted.push(false);
            }
        }
    };
    
    //-----------------------------------------------------
    // add cookies content
    //
    this.AddCookiesContent = function(vName, vValue)
    {
        // add value
        this.m_ValueName.push(vName);
        this.m_ValueContent.push(vValue);
        this.m_ValueDeleted.push(false);
    };
    
    //-----------------------------------------------------
    // set cookies content
    //
    this.SetCookiesContent = function(vName, vValue)
    {
        var vIndex = this.GetCookiesIndex(vName);
        
        // get cookies index
        if(vIndex == -1)
        {
            // add cookies content
            this.AddCookiesContent(vName, vValue);
        }
        else
        {
            // set content
            this.m_ValueContent[vIndex] = vValue;
            this.m_ValueDeleted[vIndex] = false;
        }
    };
    
    //-----------------------------------------------------
    // get cookies index
    //
    this.GetCookiesIndex = function(vName)
    {
        var vIndex = 0;
        var vValueIndex = -1;
        
        // check index
        for(vIndex = 0; vIndex < this.m_ValueName.length; vIndex++)
        {
            // check name
            if(vName == this.m_ValueName[vIndex])
            {
                vValueIndex = vIndex;
            }
        }
                
        // return value
        return vValueIndex;
    };
    
    //-----------------------------------------------------
    // get cookies content
    //
    this.GetCookiesContent = function(vName)
    {
        // get index
        var vValueIndex = this.GetCookiesIndex(vName);
        
        // get value
        if(vValueIndex == -1)
        {
            return null;
        }
        
        // return value
        return this.m_ValueContent[vValueIndex];
    };
    
    //-----------------------------------------------------
    // set cookies expires
    //
    this.SetCooiesExpires = function(vDate)
    {
        // set value
        this.m_Expires = vDate.toGMTString();
    };
    
    //-----------------------------------------------------
    // set cookies expires last day
    //
    this.SetCooiesExpiresLastDay = function(vDay)
    {
        var vDate = new Date();
        
        // set date
        vDate.setDate(vDate.getDate() + vDay);
        
        // set value
        this.m_Expires = vDate.toGMTString();
    };
    
    //-----------------------------------------------------
    // get cookies expires
    //
    this.GetCooiesExpires = function()
    {
        return this.m_Expires;
    };
    
    //-----------------------------------------------------
    // get cookies expires date
    //
    this.GetCooiesExpiresDate = function()
    {
        var vDate = Date.parse(this.m_Expires);
        
        return vDate;
    };
    
    //-----------------------------------------------------
    // set cookies domain
    //
    this.SetCooiesDomain = function(vValue)
    {
        // set value
        this.m_Domain = vValue;
    };
    
    //-----------------------------------------------------
    // get cookies domain
    //
    this.GetCooiesDomain = function()
    {
        return this.m_Domain;
    };
    
    //-----------------------------------------------------
    // set cookies path
    //
    this.SetCooiesPath = function(vValue)
    {
        // set value
        this.m_Path = vValue;
    };
    
    //-----------------------------------------------------
    // get cookies path
    //
    this.GetCooiesPath = function()
    {
        return this.m_Path;
    };
    
    //-----------------------------------------------------
    // set cookies secure
    //
    this.SetCooiesSecure = function(vValue)
    {
        // set value
        this.m_Secure = vValue;
    };
    
    //-----------------------------------------------------
    // get cookies secure
    //
    this.GetCooiesSecure = function()
    {
        return this.m_Secure;
    };
    
    //-----------------------------------------------------
    // delete document cookie now
    //
    //  information:
    //      立刻删除
    //      设置Cookies的到期时间为昨天，那么这个Cookie会被立刻删除
    //
    this.DeleteDocumentCookiesNow = function(vName)
    {    
        // document
        this.m_Document.cookie = vName + "=null; ";
    };
    
    //-----------------------------------------------------
    // delete cookies
    //
    //  information:
    //      设置删除标志，SaveCookies时电脑自动删除
    //
    this.DeleteCookies = function(vName)
    {
        // get index
        var vIndex = this.GetCookiesIndex(vName);
        
        // check index
        if(vIndex > -1)
        {
            // set delete flag
            this.m_ValueDeleted[vIndex] = true;
        }
    };
    
    //-----------------------------------------------------
    // delete value
    //
    //  information:
    //      删除数组中的内容
    //
    this.DeleteValue = function(vName)
    {
        // get index
        var vIndex = this.GetCookiesIndex(vName);
        var vTempFirstArray = null;
        var vTempSecondArray = null;
        
        // check index
        if(vIndex > -1)
        {
            // remove data at the index (name)
            vTempFirstArray = this.m_ValueName.slice(0, vIndex + 1);
            vTempSecondArray = this.m_ValueName.slice(vIndex + 1);
            vTempFirstArray.pop();
            this.m_ValueName = vTempFirstArray.concat(vTempSecondArray);
            
            // remove data at the index (content)
            vTempFirstArray = this.m_ValueContent.slice(0, vIndex + 1);
            vTempSecondArray = this.m_ValueContent.slice(vIndex + 1);
            vTempFirstArray.pop();
            this.m_ValueContent = vTempFirstArray.concat(vTempSecondArray);
            
            // remove data at the index (content)
            vTempFirstArray = this.m_ValueDeleted.slice(0, vIndex + 1);
            vTempSecondArray = this.m_ValueDeleted.slice(vIndex + 1);
            vTempFirstArray.pop();
            this.m_ValueDeleted = vTempFirstArray.concat(vTempSecondArray);
        }
    };
}

//-----------------------------------------------------------------------------
// HMI数据Cookies
//
var vHMICookies = 
{
    //=============================================================================
    // quick cookie operate load cookie
    //
    loadCookie : function (vKey, vDefValue) {
        // value
        var vValue = null;
        // create cookie object
        var vCookie = new ClientCookies(document);
        // get cookies
        vCookie.GetCookies();

        // get content
        vValue = vCookie.GetCookiesContent(vKey);
        // check UserName
        if(vValue == null)
        {
            vValue = vDefValue;
        }   

        // delete client cookies
        vCookie = null;
        
        return vValue;
    },
    //=============================================================================
    // quick cookie operate save cookie
    //
    saveCookie : function (vKey, vValue) {
        // create cookie object
        var vCookie = new ClientCookies(document);
        
        // set last day
        vCookie.SetCooiesExpiresLastDay(1000);
        
        // set value
        vCookie.SetCookiesContent(vKey, vValue);
        
        // save data
        vCookie.SaveCookies();

        // delete client cookies
        vCookie = null;
    },
    //=============================================================================
    // quick cookie operate delete cookie
    //
    deleteCookie : function (vKey) {
        // create cookie object
        var vCookie = new ClientCookies(document);
        
        // delete
        vCookie.SetCookiesContent(vKey, '');
        vCookie.DeleteCookies(vKey);
        
        // save data
        vCookie.SaveCookies();

        // delete client cookies
        vCookie = null;
    },
};

// 外部接口暴露
export default vHMICookies;