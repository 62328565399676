<template>
  <div id="app" style="height: 100%; min-height: 600px; min-width: 1000px">
    <div class="topDiv">
      <div class="logoDiv"></div>
      <span class="titleMain">{{ HMIName }}</span>
      <span class="titlePage">{{ getTitle() }}</span>
      <HMIToolbar :key="toolBarRefresh"></HMIToolbar>
    </div>
    <div class="menuDiv">
      <MenuDsp title="页面">
        <HMIPagebar
          :key="pageBarRefresh"
          v-on:pagechanged="pageChanged"
        ></HMIPagebar>
      </MenuDsp>
      <MenuDsp title="基础控件">
        <HMICtrlbar></HMICtrlbar>
      </MenuDsp>
    </div>
    <div class="attrDiv">
      <MenuDsp title="页面属性" v-show="pageAttrDsp">
        <HMIPageAttrs
          :key="pageAttrRefresh"
          v-on:pagechanged="pageChanged"
          v-on:outCaller="outCaller"
        ></HMIPageAttrs>
      </MenuDsp>
      <MenuDsp title="系统权限" v-show="pageAttrDsp">
        <HMISysPermission
          :key="pageAttrRefresh"
          v-on:pagechanged="pageChanged"
          v-on:outCaller="outCaller"
        ></HMISysPermission>
      </MenuDsp>
      <MenuDsp title="控件布局" v-show="layoutDsp">
        <HMILayout
          :key="ctrlAttrRefresh"
          v-on:ctrlchanged="ctrlChanged"
        ></HMILayout>
      </MenuDsp>
      <MenuDsp title="控件属性" v-show="ctrlAttrDsp">
        <HMIControlAttrs
          :key="ctrlAttrRefresh"
          v-on:ctrlchanged="ctrlChanged"
          v-on:outCaller="outCaller"
        ></HMIControlAttrs>
      </MenuDsp>
      <MenuDsp title="控件位置" v-show="ctrlAttrDsp">
        <HMIPosAttrs
          :key="ctrlAttrRefresh"
          v-on:ctrlchanged="ctrlChanged"
        ></HMIPosAttrs>
      </MenuDsp>
    </div>
    <HMIDrawArea
      edit="true"
      v-on:selectCtrlChanged="selectCtrlChanged"
    ></HMIDrawArea>
    <HMIImageSelector
      :key="imageSelRefresh"
      :visible="imageSelVisible"
      :name="imageSelName"
      v-on:imageChanged="imageChanged"
    ></HMIImageSelector>
    <HMITextListEditor
      :key="textLstEdtRefresh"
      :visible="textLstEdtVisible"
      v-on:textLstChanged="textLstChanged"
    ></HMITextListEditor>
    <HMIParamsDataEditor
      :key="paramsDataRefresh"
      :visible="paramsDataVisible"
      v-on:paramsDataChanged="paramsDataChanged"
    ></HMIParamsDataEditor>
    <HMIZoombar edit="true"></HMIZoombar>
    <HMIScrollbarH edit="true"></HMIScrollbarH>
    <HMIScrollbarV edit="true"></HMIScrollbarV>
    <HMILogin
      :key="loginRefresh"
      :visible="loginVisible"
      v-on:login="loginDlg"
    ></HMILogin>
  </div>
</template>
<script>
// 导入js
import HG from "../util/draw/HMIGlobal.js";
import HMIPrj from "../util/draw/HMIProject.js";
import HMIInterface from "../util/draw/HMIInterface.js";
// 导入组件
import HMIToolbar from "../components/HMIToolbar.vue";
import MenuDsp from "../components/MenuDsp.vue";
import HMIPagebar from "../components/HMIPagebar.vue";
import HMICtrlbar from "../components/HMICtrlbar.vue";
import HMIPageAttrs from "../components/HMIPageAttrs.vue";
import HMISysPermission from "../components/HMISysPermission.vue";
import HMIControlAttrs from "../components/HMIControlAttrs.vue";
import HMIPosAttrs from "../components/HMIPosAttrs.vue";
import HMILayout from "../components/HMILayout.vue";
import HMIDrawArea from "../components/HMIDrawArea.vue";
import HMIImageSelector from "../components/HMIImageSelector.vue";
import HMITextListEditor from "../components/HMITextListEditor.vue";
import HMIParamsDataEditor from "../components/HMIParamsDataEditor.vue";
import HMIZoombar from "../components/HMIZoombar.vue";
import HMIScrollbarH from "../components/HMIScrollbarH.vue";
import HMIScrollbarV from "../components/HMIScrollbarV.vue";
import HMILogin from "../components/HMILogin.vue";
import "@/assets/css/main.css";

// 当前组件属性
export default {
  // 组件名
  name: "App",
  // 创建
  created: function () {
    // 开始登录
    HMIInterface.loginSuccess = this.loadSuccess;
    HMIInterface.loginErr = this.loadErr;
    this.HMIName = window.localStorage.getItem("HMIName");
  },
  // 数据
  data: function () {
    return {
      // 工具栏刷新
      toolBarRefresh: false,

      // 页面栏刷新
      pageBarRefresh: false,
      // 页面属性刷新
      pageAttrRefresh: false,
      // 页面属性
      pageAttrDsp: true,

      // 控件属性刷新
      ctrlAttrRefresh: false,
      // 控件选中数量
      selectedCtrlsCnt: 0,
      // 控件属性
      ctrlAttrDsp: false,

      // 控件布局
      layoutDsp: false,

      // 图片选择器刷新
      imageSelRefresh: 0x10,
      // 图片选择器显示
      imageSelVisible: false,
      // 图片选择器对应属性名称
      imageSelName: "",

      // 文数对应编辑器刷新
      textLstEdtRefresh: 0x20,
      // 文数对应编辑器显示
      textLstEdtVisible: false,

      // 数据编辑器刷新
      paramsDataRefresh: 0x30,
      // 数据编辑器显示
      paramsDataVisible: false,

      // 登录画面
      loginRefresh: 0x100,
      // 是否显示
      loginVisible: true,

      // 复制控件数据
      copyControlsCnt: 0x00,
      HMIName: "物联网云组态",
    };
  },
  // 快速计算缓存
  computed: {},
  // 函数
  methods: {
    // 登录画面
    loginDlg: function (vFormParams) {
      let vUserName = "admin";
      let vPassword = "123456";
      let vplanId = 1;

      // 检查是否有效
      if (vFormParams.planId) {
        vplanId = vFormParams.planId;
      }
      if (vFormParams.userName) {
        vUserName = vFormParams.userName;
      }
      if (vFormParams.password) {
        vPassword = vFormParams.password;
      }

      // 设置planId
      HMIInterface.planID = vplanId;
      // 登录
      HMIInterface.login(vUserName, vPassword);
    },
    // 刷新登录状态
    loadSuccess: function (vPrjData) {
      // 加载
      HMIPrj.vHMIPrjFunc.loadProject(vPrjData);
      // 刷新页面菜单
      this.pageBarRefresh = !this.pageBarRefresh;
      this.pageAttrRefresh = !this.pageAttrRefresh;
      // 控件属性刷新
      this.ctrlAttrRefresh = !this.ctrlAttrRefresh;

      // 是否显示
      this.loginVisible = false;
      // 登录画面
      this.loginRefresh = this.loginRefresh ^ 0x01;
    },
    // 刷新失败状态
    loadErr: function () {
      // 是否显示
      this.loginVisible = false;
      // 登录画面
      this.loginRefresh = this.loginRefresh ^ 0x01;
    },
    // 获取标题
    getTitle: function () {
      var vPrj = null;
      // 检查项目是否存在
      if (HMIPrj.vHMIPrj) {
        // 获取当前项目
        vPrj = HMIPrj.vHMIPrj;
        // 检查项目是否有效
        if (vPrj) {
          // 获取页面名
          return vPrj.allPg[vPrj.curPg].name;
        }
      }
      // 错误
      return "";
    },
    // 页面属性改变
    pageChanged: function () {
      // 页面属性刷新
      this.pageBarRefresh = !this.pageBarRefresh;
      this.pageAttrRefresh = !this.pageAttrRefresh;
      // 控件属性刷新
      this.ctrlAttrRefresh = !this.ctrlAttrRefresh;
    },
    // 控件属性改变
    ctrlChanged: function () {
      // 暂时不做处理
    },
    // 外部调用设置选择框
    outCaller: function (type, name) {
      // 检查回调属性类型
      if (type === HG.AttrType.image) {
        //
        // 启动图片选择器对话框
        //
        // 图片选择器显示
        this.imageSelVisible = true;
        // 图片选择器刷新
        this.imageSelRefresh = this.imageSelRefresh ^ 0x01;
        // 图片选择器对应属性名称
        this.imageSelName = name;
      }
      // 检查回调属性类型
      if (type === HG.AttrType.textLstData) {
        //
        // 启动文数对应编辑器对话框
        //
        // 文数对应编辑器显示
        this.textLstEdtVisible = true;
        // 文数对应编辑器刷新
        this.textLstEdtRefresh = this.textLstEdtRefresh ^ 0x01;
      }
      // 检查回调属性类型
      if (type === HG.AttrType.paramsData) {
        //
        // 启动曲线数据编辑器对话框
        //
        // 曲线数据编辑器显示
        this.paramsDataVisible = true;
        // 曲线数据编辑器刷新
        this.paramsDataRefresh = this.paramsDataRefresh ^ 0x01;
      }
    },
    // 图片属性改变
    imageChanged: function () {
      // 页面属性刷新
      this.pageAttrRefresh = !this.pageAttrRefresh;
      // 控件属性刷新
      this.ctrlAttrRefresh = !this.ctrlAttrRefresh;
    },
    // 文本列表属性改变
    textLstChanged: function () {
      // 控件属性刷新
      this.ctrlAttrRefresh = !this.ctrlAttrRefresh;
    },
    // 数据改变刷新
    paramsDataChanged: function () {
      // 控件属性刷新
      this.ctrlAttrRefresh = !this.ctrlAttrRefresh;
    },
    // 选中控件数量改变
    selectCtrlChanged: function (selectedCnt, refreshDraw, drawStatus) {
      // 工具栏是否刷新
      let vToolbarRefresh = false;

      // 检查选中控件数量是否改变
      if (this.selectedCtrlsCnt != selectedCnt) {
        // 刷新工具栏
        vToolbarRefresh = true;
        // 设置选中控件数
        this.selectedCtrlsCnt = selectedCnt;
        // 检查选中控件数量
        if (0 == selectedCnt) {
          // 页面属性显示
          this.pageAttrDsp = true;
          // 控件布局
          this.layoutDsp = false;
        } else {
          // 页面属性隐藏
          this.pageAttrDsp = false;
          // 控件布局
          this.layoutDsp = true;
        }
        // 检查选中控件数量
        if (1 == selectedCnt) {
          // 控件属性显示
          this.ctrlAttrDsp = true;
        } else {
          // 控件属性隐藏
          this.ctrlAttrDsp = false;
        }
        // 控件属性刷新
        this.ctrlAttrRefresh = !this.ctrlAttrRefresh;
      } else {
        // 检查选中控件数量
        if (1 == selectedCnt) {
          // 检查是否刷新绘制
          if (refreshDraw) {
            // 控件属性刷新
            this.ctrlAttrRefresh = !this.ctrlAttrRefresh;
          }
        }
      }

      // 复制控件数据
      // 检查是否可以复制
      if (this.copyControlsCnt != HG.$Set.allCopyData.count) {
        // 复制数量
        this.copyControlsCnt = HG.$Set.allCopyData.count;
        // 刷新工具栏
        vToolbarRefresh = true;
      }

      // 检查页面是否改变
      if (drawStatus.pageChanged) {
        // 刷新工具栏
        vToolbarRefresh = true;
      }

      // 是否刷新工具栏
      if (vToolbarRefresh) {
        // 工具栏刷新
        this.toolBarRefresh = !this.toolBarRefresh;
      }
    },
  },
  components: {
    MenuDsp,
    HMIToolbar,
    HMIPagebar,
    HMICtrlbar,
    HMIPageAttrs,
    HMISysPermission,
    HMIControlAttrs,
    HMIPosAttrs,
    HMILayout,
    HMIDrawArea,
    HMIImageSelector,
    HMITextListEditor,
    HMIParamsDataEditor,
    HMIZoombar,
    HMIScrollbarH,
    HMIScrollbarV,
    HMILogin,
  },
};
</script>
