<template>
  <div id="prj-zoom-bar-area" :class="zoombarClass"></div>
</template>
<script>
// 导入js
import HMIZoombar from "../util/draw/HMIZoombar.js";
// 当前组件属性
export default {
  // 属性
  props: ["edit"],
  // 数据
  data: function () {
    return {
      // 是否初始化成功
      initSuccess: false,
      // 编辑模式
      editMode: false,
      // 显示样式
      zoombarClass: 'zoombar',
    };
  },
  // 创建
  created: function () {
    // 检查是否为编辑模式
    if(this.edit == 'true') {
      // 编辑模式
      this.editMode = true;
      // 显示样式
      this.zoombarClass = 'zoombar';
    } else {
      // 显示模式
      this.editMode = false;
      // 显示样式
      this.zoombarClass = 'zoombarExe';
    }
    // 初始化缩放栏
    setTimeout(this.initZoombar, 300);
  },
  // 函数
  methods: {
    // 初始化缩放栏
    initZoombar: function () {
      // 检查初始化是否成功
      if(!this.initSuccess) {
        // 创建canvas
        if(HMIZoombar.init('prj-zoom-bar-area', this.drawZoombar)) {
          // 初始化成功
          this.initSuccess = true;
        } else {
          // 刷新绘制
          requestAnimationFrame(this.initZoombar);
        }
      }
    },
    // 绘制整个画面
    drawZoombar: function () {
      // 检查初始化是否成功
      if(this.initSuccess) {
        // 绘制
        HMIZoombar.draw();
      }
    },
  },
};
</script>
