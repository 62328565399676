<template>
  <el-dialog
    title="组态登录"
    top="60px"
    :modal="true"
    :visible.sync="dlgVisible"
    width="500px"
  >
    <el-form :model="form">
      <el-form-item label="登录名" :label-width="formLabelWidth">
        <el-input v-model="form.userName" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="密码" :label-width="formLabelWidth">
        <el-input
          v-model="form.password"
          autocomplete="off"
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item label="Plan Id" :label-width="formLabelWidth">
        <el-input v-model="form.planId" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <span id="spanLoginInfo" class="loadingSpan"> 登录中，请等待... </span>
      <el-button type="primary" @click="submit()" size="mini">登录</el-button>
    </span>
  </el-dialog>
</template>
<script>
// 导入js
import HMICookies from "../util/draw/HMICookies.js";
// 当前组件属性
export default {
  // 属性
  props: ["visible"],
  // 数据
  data: function () {
    return {
      // 对话框是否显示
      dlgVisible: this.visible,
      // 对话框内容
      form: {
        userName: "admin",
        password: "",
        planId: 1,
      },
      // 标签宽度
      formLabelWidth: "120px",
    };
  },
  // 创建
  created: function () {
    // 读取Cookies
    this.form.userName = HMICookies.loadCookie("userName", "admin");
    this.form.planId = HMICookies.loadCookie("planId", 1);
  },
  // 函数
  methods: {
    // 确定
    submit: function () {
      // 控件
      let vLoginInfo = document.getElementById("spanLoginInfo");
      // 设置可见
      vLoginInfo.style.display = "inherit";

      // 写入Cookies
      HMICookies.saveCookie("userName", this.form.userName);
      HMICookies.saveCookie("planId", this.form.planId);

      // 页面属性改变
      this.$emit("login", this.form);
    },
  },
};
</script>
